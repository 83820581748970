.banner-list-box {
    border: 1px solid #000;
    border-radius: 4px;
    align-items: center;
    margin: 5px;
    
    .banner-list-text{
        font-size: 1.5rem;
    }

    .banner-list-delete-icon{
        font-size: 2rem;
    }
}

.banner-list-box:hover {
    background-color: lightblue;
}



.banner-list-item {
    display: flex;
    justify-content: center; 
    height: 100%; 
}

