iframe#webpack-dev-server-client-overlay{display:none!important}

.App {
  text-align: left;
  background: #EEE;
}

.App-logo {
  height: 2em;
  margin: 1em 0;
  text-align: center;
}

.App-logo img{
  height: 2em;
}

.main-content {
  padding: 1em;
  width: 100%;
}

.main-content .MuiPaper-root{
  padding: 1em;
}

.actions-bar button{
  height:100%;
  float:right;
}

.ps-menuitem-root.ps-active {
  background: #008e9c;
  color: white;
}

.ps-menuitem-root.ps-active:hover{
  color: #008e9c;
}

.tools button svg, .tools a svg{
  margin-left:0.5em;
}

.tools .MuiGrid-item {
  text-align: right;
}

a.MuiLink-root{
  cursor: pointer;
}

.placeholder-ul{
  visibility: hidden;
}
.placeholder-li:hover {
  background: #F1F1F1;
}